import '../../second_cont.css'
function Creds() {
	return (
		<div className="pt-5 pb-5">
			<h5> School Courses - UC Irvine : </h5>
			Courses in Progress...
			<br></br>
			<br></br>
			<h5> School Courses - Laney college : </h5>
			<b> CIS 25 </b> - Programming using c++ (A+) &nbsp; <b> CIS 27 </b> - Data Structures and Algorithms
			(A)
			<br></br>
			<b> CIS 61 </b> - Struc/Object Oriented Programming (A)
			<br></br>
			<br></br>
			<h5> Certifications :</h5>
			<b>Automation Pro Workato</b> - Issued Aug, 2023 &nbsp;
			<br></br>
			<b>Google It Support Proffesional</b> - Issued Feb, 2021 &nbsp;
			<br></br>
			<b>Excel Proficiency </b> - Issued Sep, 2020 &nbsp;
		</div>
	)
}
export default Creds
